<template>
  <div class="SellWebsiteView">
    SellWebsiteView
  </div>
</template>

<script>
export default {
  name: 'SellWebsiteView',
  data() {
    return {
      msg: 'Welcome to Yosur Vue.js App',
    };
  },
};
</script>
